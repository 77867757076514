import React from "react";
import GraphQLErrorList from "../components/graphql-error-list";


const Errors = ({ errors }) => (
 
    <GraphQLErrorList errors={errors} />
 
);

export default Errors;
